import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import SearchBar from './SearchBar';
import Skeleton from 'react-loading-skeleton';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { motion } from "framer-motion";
import { Building, ExclamationSquareFill, ArrowRight } from 'react-bootstrap-icons';
import "react-loading-skeleton/dist/skeleton.css";

export default class Results extends Component {

    renderWarning = (showWarning) => {
        if (!showWarning) {
            return null;
        }

        return (
            <Row id="hero-search">
                <Col xs={12} md={12} lg={12}>
                    <Alert variant={"warning"}>
                        <ExclamationSquareFill /> There was an issue searching your result!
                    </Alert>
                </Col>
            </Row>
        )
    }

    renderResultHeader = (isLoading) => {
        if (isLoading) {
            return <Skeleton count={1} height={20} />
        }

        const { data } = this.props;

        return (
            <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} initial={{ opacity: 0 }} >
                <p>We found <strong>{data === null ? "0" : data.length}</strong> result(s) for your search</p>
            </motion.div>
        )
    }

    renderResults = (isLoading, data) => {
        if (isLoading) {
            return <Skeleton count={5} height={40} />
        }

        if (data === null || data.length === 0) {
            return (
                <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} initial={{ opacity: 0 }} >
                    <h5>Please check your search and try again.</h5 >
                </motion.div>
            )
        }

        const { onItemSelect } = this.props;
        var results = data.map(org =>
            <ListGroup.Item onClick={() => onItemSelect(org.customerNo, org.name, org.nsInternalID, org.pNumber)} key={org.pNumber} action>
                <Row>
                    <Col xs="auto">
                        <Building className="primary-color align-items-center leading-icon-row" />
                    </Col>
                    <Col className="py-0 px-10">
                        {`${org.name}, `} {<b>{org.suburb.toUpperCase()},</b>} {org.state ? <b>{org.state}</b> : null} {org.postcode ? <b>{org.postcode}</b> : null}
                    </Col>
                    <Col xs="auto">
                        <ArrowRight className="primary-color chevron trailing-icon-row" />
                    </Col>
                </Row>
            </ListGroup.Item>
        )

        return (
            <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} initial={{ opacity: 0 }} >
                <ListGroup className="shadow">
                    {results}
                </ListGroup>
            </motion.div>
        )
    }

    render() {
        const { isLoading, searchTerm, searchState, data, showWarning, searchBy, onSearch } = this.props;
        return (
            <div>
                <Container>
                    <Row id="hero-search">
                        <Col xs={12} md={12} lg={12}>
                            <SearchBar
                                term={searchTerm}
                                state={searchState}
                                searchBy={searchBy}
                                onSearch={onSearch} />
                        </Col>
                    </Row>
                    {this.renderWarning(showWarning)}
                    <Row className="page-headline">
                        <Col xs={12} md={12} lg={12}>
                            {this.renderResultHeader(isLoading)}
                        </Col>
                    </Row>
                    <Row id="search-result">
                        <Col xs={12} md={12} lg={12}>
                            {this.renderResults(isLoading, data)}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
